<template>
  <div id="ads">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("settingTabs.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("advertising.AdsListTitle") }}
        </h5>
      </b-col>
      <b-col
        v-if="!isExternal"
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="AddAnuncios"
        >
          <span class="text-nowrap">{{ $t("advertising.AdAdd") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end align-items-center">
        <router-link to="/advertising/campaigns">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-row
      v-if="ads && ads.length === 0 && !isExternal"
      class="mt-2"
    >
      <b-col>
        <b-jumbotron
          bg-variant="transparent"
          border-variant="primary"
          :header="$t('advertising.NotAdds')"
        >
          <p class="text-primary">
            {{ $t("advertising.NotAddOnCampaign") }}
          </p>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-row
      v-else-if="isExternal"
      class="mt-2"
    >
      <b-col>
        <b-jumbotron
          bg-variant="transparent"
          border-variant="primary"
          :header="$t('advertising.CampaignExternal')"
        >
          <p class="text-primary">
            {{ $t("advertising.NotAddOnCampaign") }}
          </p>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="ad in ads"
        :key="ad.node.id"
        class="mt-2"
        md="6"
        lg="3"
        xl="3"
        sm="12"
      >
        <!-- <ad-list-card :folder="ad" @delete-folder="deleteAd" /> -->
        <b-card
          class="cardContent"
          no-body
        >
          <b-img
            :src="buildImageUrl(ad)"
            class="card-img-top cursor-pointer"
            img-alt="Card image"
            fluid-grow
            @click="routeToEditView(ad.node.id)"
            @error="errorImg"
          />
          <b-card-header>
            <div id="acortarPadre">
              <b-card-title
                id="acortar"
                class="mb-25"
              >
                <span
                  class="mr-50 bullet bullet-success bullet-sm"
                  :class="{
                    'bullet-success': ad.node.isActive,
                    'bullet-danger': !ad.node.isActive
                  }"
                />{{ ad.node.name }}
              </b-card-title>
              <b-card-text class="mb-0">
                {{ $t("advertising.Add") }}
              </b-card-text>
            </div>
          </b-card-header>

          <b-card-body>
            <div class="actions">
              <div class="action">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      color="white"
                      icon="MoreVerticalIcon"
                      size="20"
                    />
                  </template>
                  <b-dropdown-item @click="routeToEditView(ad.node.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      size="18"
                    />
                    {{ $t("advertising.EditAd") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteFolder(ad.node.id)">
                    <feather-icon
                      icon="DeleteIcon"
                      size="18"
                    />
                    {{ $t("advertising.DropAd") }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BImg,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BJumbotron,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, utf8ToB64 } from '@/store/functions'

import * as constants from '@core/utils/constants'

import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BImg,
    BDropdownItem,
    BJumbotron,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ads: null,
      isSponsor: false,
      userData: getUserData(),
      isExternal: false,
      noImageUrl: '@/assets/images/backend/nocover.jpeg',
    }
  },
  destroyed() {
    localStorage.removeItem('isExternal')
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
    this.isExternal = JSON.parse(localStorage.getItem('isExternal'))
    this.getData()
  },
  methods: {
    getData() {
      const { campaign } = this.$route.params
      axios
        .post('', {
          query: `
           query{
            allAds(campaing: "${campaign}"
            ){
              edges {
                node {
                  id
                  creator {
                    id
                  }                  
                  name
                  description
                  type                  
                  campaing{
                    name
                    creator {
                      id
                    } 
                  }
                  content{
                    name
                    imageUrl
                  }
                  categories${this.isSponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''} {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }                  
                  contents ${this.isSponsor ? `(creator:"${utf8ToB64(`id:${this.userData.id}`)}")` : ''}{
                    edges {
                      node {
                        id
                        name
                        imageUrl
                      }
                    }
                  }
                  prints
                  timeout
                  isActive                  
                }
              }
            }              
          }          
        `,
        })
        .then(result => {
          messageError(result, this)

          this.ads = result.data.data.allAds.edges
          if (this.isSponsor) {
            let authorized = false
            const campaignCreator = this.ads[0].node.campaing.creator
            if (campaignCreator !== null) {
              if (campaignCreator.id === this.userData.id) { authorized = true }
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }
          }
        })
        .catch(() => { })
    },

    routeToAdsView(folderId) {
      const { campaign } = this.$route.params

      this.$router.push({
        name: 'ads-view',
        params: { id: folderId, campaign },
      })
    },
    AddAnuncios() {
      const { campaign } = this.$route.params

      this.$router.push({ name: 'ads-create', params: { campaign } })
    },
    deleteAd(id) {
      this.ads = this.ads.filter(item => item.node.id !== id)
    },
    deleteFolder(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteAd(id:"${id}") {
              found
              deletedId
            }
          }`,
            })
            .then(res => {
              messageError(res, this)

              this.$emit('delete-folder', res.data.data.deleteAd.deletedId)
              this.deleteAd(id)
            })
        }
      })
    },
    routeToEditView(adId) {
      this.$router.push({ name: 'ads-edit', params: { id: adId } })
    },
    fetchData(folder) {
      this.$refs.categoryCard.fetchData(null, folder)
    },
    buildImageUrl(folder) {
      if (folder.node.content && folder.node.content.imageUrl) {
        return folder.node.content.imageUrl == null
          || folder.node.content.imageUrl.length === 0
          ? noCover
          : folder.node.content.imageUrl
      }
      return noCover
    },
    errorImg(e) {
      e.target.src = fileError
    },
  },
}
</script>
<style scoped>
#ads .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#ads .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#ads .card-body {
  padding: 1rem;
}

#ads .card-body h4 {
  font-size: 1rem !important;
}

#ads .card-header {
  padding: 1rem;
}

#ads .card-header h4 {
  font-size: 1rem !important;
}

#ads .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/*.folder {
  min-height: 150px;
  height: 300px;
  cursor: all-scroll;
}*/
#ads .dropContainer {
  border: 3px dashed;
}

/*.card-width {
  width: 200px;
}*/
#ads .list-group-item {
  transition: all 1s;
}

#ads #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#ads #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
